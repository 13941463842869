
export default function Test(){
  return(
    <div>
    
      <button>
        Click to Enter
      </button>
    </div>
  )
}